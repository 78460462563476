import "./scss/oms-app.scss";
import "semantic-ui-css/semantic.min.css";
import { Provider } from "react-redux";
import { initUserManager, RpmOidc } from "rpm-ui-components";
import * as Oidc from "oidc-client";
import { history, store } from "./store/store";
import RouterComponent from "./modules/Main/RouterComponent";
import { AUTH_CONFIG } from "./utils/authConfig";
import HeaderComponent from "./components/HeaderComponent";
import MessageViewer from "./modules/MessageViewer/MessageViewer";
import Footer from "./components/Footer";
import AppContext from "./modules/Main/AppContext";
import { localeOptions } from "./utils/localeOptions";
import { createRoot } from "react-dom/client";
import "react-datepicker/dist/react-datepicker.min.css";
import * as Sentry from "@sentry/react";

Oidc.Log.logger = console;

Sentry.init({
  dsn: "https://51b2ed16ad3c697fc828f8013368eaa7@o4507249409196032.ingest.us.sentry.io/4507294191386624",
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
const isEU = import.meta.env.VITE_APP_LOCALE === localeOptions.EU;
const dateFormat = isEU ? "dd.MM.yyyy" : "MM/dd/yyyy";
const momentDateFormat = isEU ? "DD.MM.YYYY" : "MM/DD/YYYY";

initUserManager(AUTH_CONFIG);

root.render(
  <Provider store={store}>
    <RpmOidc
      store={store}
      history={history}
      redirectPath="/oidc-callback"
      callbackPageContent={<div>Redirecting...</div>}
      useRedirectHooks={true}
    >
      <AppContext.Provider value={{ isEU, dateFormat, momentDateFormat }}>
        <MessageViewer />
        <div className="app-container">
          <div className="app-header-container ">
            <HeaderComponent history={history} />
          </div>
          <div className="app-main-container">
            <RouterComponent />
          </div>
          <Footer />
        </div>
      </AppContext.Provider>
    </RpmOidc>
  </Provider>
);
