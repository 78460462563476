import React, { useContext } from "react";
import { Segment, Grid, Popup, Icon } from "semantic-ui-react";
import styled from "styled-components";
import IconComponent, { iconNameEnum } from "../../../components/IconComponent";
import getFormattedDate from "../../../utils/DateExtensions";
import locationFormatter from "../../../utils/USStatesProvider";
import CompanyLogoComponent from "../../../components/CompanyLogoComponent";
import { OrderState } from "../../../utils/constants";
import AppContext from "../../Main/AppContext";
import VehicleActionsRightPanel from "./VehicleActionsRightPanel";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getWeightUnit,
  getLengthUnit,
} from "../../../utils/helpers/unitConversionHelper";

import "../../../scss/variables.scss";

import {
  openOrderUpdateForm,
  toggleOrderState,
  addSelected as addOrderSelected,
  removeSelected as removeOrderSelected,
} from "../../../store/orderSlice";
import {
  addSelectedShipment,
  removeSelectedShipment,
} from "../../../store/shipmentSlice";
import { OrderObject } from "../../../models/OrderObject";
import { ModifiedShipmentObject } from "../../../models/ShipmentObject";

import moment from "moment";
import displayDateTime from "../../../utils/dateTimeHelper";
import { OrderLocation } from "../../../models/OrderLocation";

type VehicleProps = {
  vehicle: OrderObject | null;
  isOrder: boolean;
  selectedOrderIds: number[];
  shipment?: ModifiedShipmentObject;
};

const Vehicle = ({
  vehicle,
  isOrder,
  selectedOrderIds,
  shipment,
}: VehicleProps) => {
  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const vehicleModalOpen = useAppSelector(
    (state) => state.orders.vehicleModalOpen
  );

  const clicked = (id: number, shipment?: ModifiedShipmentObject) => {
    if (vehicleModalOpen) return;
    if (isOrder) {
      if (!selectedOrderIds.find((orderId) => orderId === id)) {
        dispatch(addOrderSelected(id));
      } else {
        dispatch(removeOrderSelected(id));
      }
    } else if (shipment && vehicle) {
      const selectedShipment = {
        shipment: shipment,
        orderIds: [vehicle.id],
      };

      if (!selectedOrderIds.find((orderId) => orderId === id)) {
        dispatch(addSelectedShipment(selectedShipment));
      } else {
        dispatch(removeSelectedShipment(selectedShipment));
      }
    }
  };

  const getLocationInformation = (orderLocation?: OrderLocation) => {
    const city = orderLocation?.city
      ? locationFormatter.toTitleCaseFrom(orderLocation.city)
      : "Not provided";
    const state = orderLocation?.state ? orderLocation.state : "Unknown";
    const country = orderLocation?.country ? orderLocation.country : "Unknown";
    const location = orderLocation?.name
      ? locationFormatter.toTitleCaseFrom(orderLocation.name)
      : "Unknown";
    const zip = orderLocation?.zip;

    return { city, state, country, location, zip };
  };

  const determineLocationDisplay = (
    isEU: boolean,
    country: string,
    state: string
  ) => {
    return !isEU || country === "Unknown" ? state : country;
  };

  if (!vehicle) return <EmptyVehiceStyled />;

  const { pickup, delivery, vin, items, state } = vehicle;
  const item = items?.[0];

  const {
    city: pickupCity,
    state: pickupState,
    country: pickupCountry,
    location: pickupLocation,
  } = getLocationInformation(pickup);

  const {
    city: deliveryCity,
    state: deliveryState,
    country: deliveryCountry,
    location: deliveryLocation,
  } = getLocationInformation(delivery);

  const iconNameType = item?.vehicleType
    ? iconNameEnum[item.vehicleType.toUpperCase()]
    : null;

  const identityNumber = item?.identityNumber;
  const model = item?.model;

  const handleStartOrderUpdate = (
    orderId: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    dispatch(openOrderUpdateForm(orderId));
    event.stopPropagation();
  };

  const handleOrderStateChange = (vehicleState: number) => {
    dispatch(
      toggleOrderState({
        ...vehicle,
        state: vehicleState,
      })
    )
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
  };

  const getAgingColor = (order: OrderObject, isInActive: number) => {
    const styles = getComputedStyle(document.documentElement);

    let defaultColor = styles.getPropertyValue("--color-background-active");
    if (isInActive > 0)
      defaultColor = styles.getPropertyValue("--color-background-inactive");
    return order?.vinAging?.vinAgingPriorities?.colorScheme ?? defaultColor;
  };

  const getVinAgingDetails = (order: OrderObject) => {
    if (order?.vinAging === null) {
      return null;
    }
    let strVinAge = "";
    const vinAge = order?.vinAging?.vinAge;
    if (vinAge === 1) {
      strVinAge = "1 day old.";
    } else {
      strVinAge = vinAge + " days old.";
    }
    if (order?.vinAging?.releaseDate === null) {
      return <div style={{ textAlign: "center" }}>{strVinAge}</div>;
    }
    let strReleaseDate = "";
    const releaseDate = moment(order?.vinAging?.releaseDate);
    if (releaseDate.isValid() === true) {
      strReleaseDate = displayDateTime(releaseDate, "MM/DD/YY h:mm A");
      return (
        <div style={{ textAlign: "center" }}>
          {strVinAge} <br />
          {strReleaseDate}
        </div>
      );
    }
    return <div style={{ textAlign: "center" }}>{strVinAge}</div>;
  };

  return (
    <Segment
      style={{
        display: "flex",
        paddingRight: 0,
        boxShadow: "none",
        cursor: "pointer",
        border:
          vehicle.state === 15
            ? "1.5px solid #ff4d63"
            : "1px solid rgba(34,36,38,.15)",
      }}
      onClick={() => clicked(vehicle.id, shipment)}
      className={
        selectedOrderIds.find((orderId) => orderId === vehicle.id)
          ? "selected"
          : ""
      }
    >
      <Grid
        style={{
          paddingRight: 0,
          borderRight: "1px solid rgba(34,36,38,.15)",
        }}
      >
        <VehicleInfoRow columns={3} verticalAlign="middle">
          <HeaderLeftColumn>
            <VehicleText>
              {locationFormatter.abbreviate(pickupLocation, 12)}
            </VehicleText>
            <VehicleText>
              {locationFormatter.abbreviate(pickupCity)},{" "}
              {determineLocationDisplay(
                appContext.isEU,
                pickupCountry,
                pickupState
              )}
            </VehicleText>
          </HeaderLeftColumn>
          <Grid.Column style={{ whiteSpace: "nowrap" }}>
            <ArrowIcon>
              <IconComponent name={iconNameEnum.ARROW} />
            </ArrowIcon>
          </Grid.Column>
          <HeaderRightColumn textAlign="right">
            <VehicleText>
              {locationFormatter.abbreviate(deliveryLocation, 12)}
            </VehicleText>
            <VehicleText>
              {locationFormatter.abbreviate(deliveryCity)},{" "}
              {determineLocationDisplay(
                appContext.isEU,
                deliveryCountry,
                deliveryState
              )}
            </VehicleText>
          </HeaderRightColumn>
        </VehicleInfoRow>
        <VehicleInfoRow columns={2}>
          <VehicleDate>
            <VehicleText>
              {getFormattedDate(
                vehicle.pickup?.appointment
                  ? vehicle.pickup.appointment.start
                  : null,
                appContext.momentDateFormat
              )}
            </VehicleText>
          </VehicleDate>
          <VehicleDate textAlign="right">
            <VehicleText>
              {getFormattedDate(
                vehicle.delivery?.appointment
                  ? vehicle.delivery.appointment.start
                  : null,
                appContext.momentDateFormat
              )}
            </VehicleText>
          </VehicleDate>
        </VehicleInfoRow>
        <VehicleDetailsRow columns={1}>
          <VehicleDetails>
            <div>
              <strong>Weight:</strong>{" "}
              {getWeightUnit(item?.weight ?? 0, appContext.isEU)}
            </div>
            <div>
              <strong>L:</strong>{" "}
              {getLengthUnit(item?.length ?? 0, appContext.isEU)}{" "}
              <strong>W:</strong>{" "}
              {getLengthUnit(item?.width ?? 0, appContext.isEU)}{" "}
              <strong>H:</strong>{" "}
              {getLengthUnit(item?.height ?? 0, appContext.isEU)}
            </div>
          </VehicleDetails>
          <VehicleDetails>
            <div />
            <div>
              <strong>Wheelbase:</strong>{" "}
              {getLengthUnit(
                item?.wheelbase ? Number(item.wheelbase) : 0,
                appContext.isEU
              )}
            </div>
          </VehicleDetails>
          <VehicleDetails>
            <div>{model ?? "Unknown"}</div>
            {getVinAgingDetails(vehicle) !== null ? (
              <Popup
                style={{ backgroundColor: "black", color: "white" }}
                content={getVinAgingDetails(vehicle)}
                trigger={
                  <div>
                    <strong>VIN:</strong> {vin ?? identityNumber}
                  </div>
                }
              />
            ) : (
              <div>
                <strong>VIN:</strong> {vin ?? identityNumber}
              </div>
            )}
          </VehicleDetails>
        </VehicleDetailsRow>
        <VehicleFooter
          style={{
            backgroundColor: getAgingColor(vehicle, OrderState.Invisible),
          }}
          className={state === OrderState.Invisible ? "inactive" : "active"}
        >
          <VehicleDetails>
            <Logos>
              {vehicle.vinAging?.vinPriority === 1 && (
                <CarLogo>
                  <Icon name="warning sign" size="large" />
                </CarLogo>
              )}
              {vehicle.customer && (
                <CompanyLogoComponent
                  logoSource={vehicle.customer.slCustomerImg ?? ""}
                  logoClassName="shipment-item-footer__image"
                  companyName={vehicle.customer.slCustomerName ?? ""}
                />
              )}
              <CarLogo>
                <IconComponent
                  name={iconNameType ?? iconNameEnum.CAR}
                  iconClass="icon"
                />
              </CarLogo>
            </Logos>
            <OnHold defaultChecked={vehicle.state === 15}>on Hold</OnHold>
            <VehiclePrice>
              {vehicle.currencySign ?? "N/A "}
              {Number(vehicle.costAmount).toFixed(2)}
            </VehiclePrice>
          </VehicleDetails>
        </VehicleFooter>
      </Grid>
      <VehicleActionsRightPanel
        onStartOrderUpdate={handleStartOrderUpdate}
        onOrderStateChange={handleOrderStateChange}
        isOrder={isOrder}
        identityNumber={item?.identityNumber ?? ""}
        isUnsaved={vehicle.isUnsaved ?? false}
        state={vehicle.state ?? 0}
        id={vehicle.id ?? 0}
      />
    </Segment>
  );
};

export default Vehicle;

const EmptyVehiceStyled = styled(Segment)`
  height: 140px;
  width: 100%;
  border-style: dashed !important;
  box-shadow: none !important;
`;

const VehicleDetailsRow = styled(Grid.Row)`
  background-color: #c9c9c9;
  color: #707070;
  font-size: 0.8rem;
  padding: 0 !important;
  margin-top: 0.5rem;
`;

const VehicleDetails = styled(Grid.Column)`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftColumn = styled(Grid.Column)`
  padding-right: 0 !important;
`;

const HeaderRightColumn = styled(Grid.Column)`
  padding-left: 0 !important;
`;

const VehicleText = styled.p`
  margin-bottom: 0;
  font-size: 0.75rem;
`;

const ArrowIcon = styled.div`
  width: 1.5rem;
  margin: 0 auto;
`;

const VehicleInfoRow = styled(Grid.Row)`
  padding: 0 !important;
  margin-top: 0.5rem;
`;

const VehicleDate = styled(Grid.Column)`
  :not(:first-child) {
    padding-left: 0.1rem !important;
  }
  :first-child {
    padding-right: 0.1rem !important;
  }
`;

const VehicleFooter = styled(Grid.Row)`
  padding: 0.5rem 0 !important;
`;

const CarLogo = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
`;

const VehiclePrice = styled.div`
  text-align: right;
  font-size: 0.8rem;
`;

const Logos = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
`;

const OnHold = styled.div`
  background-color: #ff4d63;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  position: absolute;
  left: calc(50% + 13px);
  transform: translate(-50%, 25%);
  display: ${(props) => (props.defaultChecked ? "visible" : "none")};
`;
