import {
  Form,
  Input,
  Select,
  Icon,
  InputOnChangeData,
} from "semantic-ui-react";
import styled from "styled-components";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { ChangeEvent, useEffect } from "react";
import { MappedEnumItem } from "../../models/MappedEnumItem";
import { FormCostObject } from "../../models/FormCostObject";
import { useAppSelector } from "../../store/hooks";
import { mapEnumToOption } from "../OrderPanel/Modals/OrderPanelOrderDetailsModal/DetailsModal";
import { BaseFormObject } from "../../models/BaseFormObject";

const ReadonlyInputStyled = styled(Input)`
  opacity: 0.7 !important;
`;

export type CostItemProps = {
  control: Control<BaseFormObject>;
  cost: FormCostObject;
  setValue: UseFormSetValue<any>;
  removeCost: () => void;
  currencySign: string;
  costIndex: number;
};

const CostItem = (props: CostItemProps) => {
  const costLineItemTypes = useAppSelector(
    (state) => state.orders.additionalData.costLineItemTypes
  ).map<MappedEnumItem>(mapEnumToOption);

  const updateAmount = (qty: string, price: string) => {
    const amount = +qty * +price;

    props.setValue(`costs.${props.costIndex}.amount`, amount.toFixed(2));
  };

  useEffect(() => {
    // Some costs from BE don't have amount properly set, so recalculate on cost load
    updateAmount(
      props.cost.qty?.toString() ?? "0",
      props.cost.price?.toString() ?? "0"
    );
  }, []);

  const isFirstItem = props.costIndex === 0;

  return (
    <Form.Group>
      <Controller
        name={`costs.${props.costIndex}.code`}
        control={props.control}
        rules={{
          validate: (code) => {
            return code?.key !== "" || "Cost type is required";
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Form.Field
            id={`costs.${props.costIndex}.code`}
            control={Select}
            label={isFirstItem && "Type"}
            placeholder="Type"
            width={4}
            options={costLineItemTypes}
            value={value.key}
            onChange={(
              _: ChangeEvent<HTMLInputElement>,
              { value }: InputOnChangeData
            ) => {
              const selectedCostLineItem = costLineItemTypes.find(
                (type) => type.value === value
              ) ?? { value: "", text: "" };
              onChange({
                key: selectedCostLineItem.value,
                value: selectedCostLineItem.text,
              });
            }}
            search={(opts: MappedEnumItem[], value: string) =>
              opts.filter((opt) => new RegExp(`^${value}`, "mi").test(opt.text))
            }
            required
          />
        )}
      />

      <Controller
        name={`costs.${props.costIndex}.qty`}
        control={props.control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Form.Field
            id={`costs.${props.costIndex}.qty`}
            control={Input}
            label={isFirstItem && "Qty"}
            placeholder="Qty"
            width={2}
            onChange={(
              _: ChangeEvent<HTMLInputElement>,
              { value }: InputOnChangeData
            ) => {
              onChange(value);
              updateAmount(value, props.cost.price?.toString() ?? "0");
            }}
            value={value}
            required
          />
        )}
      />

      <Controller
        name={`costs.${props.costIndex}.price`}
        control={props.control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Form.Field
            id={`costs.${props.costIndex}.price`}
            control={Input}
            label={isFirstItem && "Price"}
            width={2}
            required
          >
            <Input
              id={`costs.${props.costIndex}.price`}
              onChange={(
                _: ChangeEvent<HTMLInputElement>,
                { value }: InputOnChangeData
              ) => {
                onChange(value);
                updateAmount(props.cost.qty, value);
              }}
              value={value}
              placeholder="Price"
              label={props.currencySign}
              required
            />
          </Form.Field>
        )}
      />

      <Form.Field width={2} style={{ cursor: "auto" }}>
        {isFirstItem && (
          <label htmlFor={`costs.${props.costIndex}.amount`}>Amount</label>
        )}
        <ReadonlyInputStyled
          id={`costs.${props.costIndex}.amount`}
          value={Number(props.cost.amount).toLocaleString("en", {
            useGrouping: false,
            minimumFractionDigits: 2,
          })}
          label={props.currencySign}
          disabled
        />
      </Form.Field>

      <Controller
        name={`costs.${props.costIndex}.note`}
        control={props.control}
        render={({ field: { onChange, value } }) => (
          <Form.Field
            id={`costs.${props.costIndex}.note`}
            control={Input}
            label={isFirstItem && "Notes"}
            placeholder="Notes"
            width={5}
            onChange={(
              _: ChangeEvent<HTMLInputElement>,
              { value }: InputOnChangeData
            ) => onChange(value)}
            value={value}
          />
        )}
      />

      <Form.Field
        style={{
          paddingTop: isFirstItem ? "23px" : "5px",
          paddingLeft: "15px",
        }}
        id={`costs.${props.costIndex}.remove`}
        onClick={props.removeCost}
        control={Icon}
        width={1}
        name="trash"
        size="large"
        link
        aria-label="Remove Cost"
      />
    </Form.Group>
  );
};

export default CostItem;
