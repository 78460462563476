import { useState, useEffect, useMemo, useContext } from "react";
import AppContext from "../../Main/AppContext";
import debounce from "lodash-es/debounce";
import styled from "styled-components";

import { useAppSelector } from "../../../store/hooks";
import { OrdersFilter } from "../../../models/Filter";
import { VinPriority } from "../../../models/VinPriority";
import { VinEquality } from "../../../models/VinEquality";
import FilterForm from "../../FilterForm/FilterForm";

const vinEquality: VinEquality[] = [
  {
    id: 1,
    symbol: "=",
    description:
      "Filters only for VINs with the same age as the integer applied",
    value: "=",
  },
  {
    id: 2,
    symbol: ">",
    description:
      "Filters for VINs with an age greater than the integer applied",
    value: ">",
  },
  {
    id: 3,
    symbol: "<",
    description: "Filters for VINs with an age lesser than the integer applied",
    value: "<",
  },
  {
    id: 4,
    symbol: ">=",
    description:
      "Filters for VINs with an age equal to or greater than the integer applied",
    value: "≥",
  },
  {
    id: 5,
    symbol: "<=",
    description:
      "Filters for VINs with an age equal to or lesser than the integer applied",
    value: "≤",
  },
];

const vinPriority: VinPriority[] = [
  { id: 1, value: "1st" },
  { id: 2, value: "2nd" },
  { id: 3, value: "3rd" },
];

type OrderPanelFilterProps = {
  isSearchOnly: boolean;
  onFilterChange: (filter: OrdersFilter) => void;
};

const OrderPanelFilter = (props: OrderPanelFilterProps) => {
  const appContext = useContext(AppContext);
  const orderFilter = useAppSelector((state) => state.orders.filter);
  const customersList = useAppSelector((state) => state.customers.list);

  const [filter, setFilter] = useState<OrdersFilter>(orderFilter);

  useEffect(() => {
    setFilter(orderFilter);
  }, [orderFilter]);

  const debouncedOnFilterChange = useMemo(
    () =>
      debounce((filter: OrdersFilter) => {
        props.onFilterChange(filter);
      }, 750),
    [props.onFilterChange]
  );

  const filterChangeHandler = (
    partialFilter: Partial<OrdersFilter>,
    callback: (filter: OrdersFilter) => void
  ) => {
    const updatedFilter = {
      ...filter,
      ...partialFilter,
      page: 1,
      pageSize: 20,
    };

    setFilter(updatedFilter);
    callback(updatedFilter);
  };

  const customerOptions = useMemo(
    () =>
      customersList
        .map((x) => ({ key: x.id, value: x.id, text: x.name }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [customersList]
  );

  return (
    <FilterForm>
      <FilterForm.Grid>
        {props.isSearchOnly ? null : (
          <>
            <FilterForm.Row columns={2}>
              <FilterForm.Column>
                <FilterForm.Origin
                  value={filter.origin}
                  onChange={(value) =>
                    filterChangeHandler(
                      { origin: value },
                      debouncedOnFilterChange
                    )
                  }
                />
              </FilterForm.Column>

              <FilterForm.Column>
                <FilterForm.Destination
                  value={filter.destination}
                  onChange={(value) =>
                    filterChangeHandler(
                      { destination: value },
                      debouncedOnFilterChange
                    )
                  }
                />
              </FilterForm.Column>
            </FilterForm.Row>

            <FilterForm.Row columns={4}>
              <FilterForm.Column>
                <FilterForm.PickupDate
                  value={filter.pickupDate}
                  dateFormat={appContext.dateFormat}
                  onChange={(value) =>
                    filterChangeHandler(
                      { pickupDate: value },
                      props.onFilterChange
                    )
                  }
                />
              </FilterForm.Column>

              <FilterForm.Column>
                <FilterForm.DeliveryDate
                  value={filter.deliveryDate}
                  dateFormat={appContext.dateFormat}
                  onChange={(value) =>
                    filterChangeHandler(
                      { deliveryDate: value },
                      props.onFilterChange
                    )
                  }
                />
              </FilterForm.Column>

              <FilterForm.Column>
                <FilterForm.Field>
                  <label>Aging VIN Priority</label>
                  <StyledVinPriorityDiv>
                    {vinPriority.map((vinPriority) => (
                      <FilterForm.VinPriority
                        key={vinPriority.id}
                        vinPriority={vinPriority}
                        onChange={(value) => {
                          const filteredVinPriority = filter.vinPriority.filter(
                            (vinPriority) => vinPriority !== value
                          );

                          const vinPriority =
                            filteredVinPriority.length ===
                            filter.vinPriority.length
                              ? [...filter.vinPriority, value]
                              : filteredVinPriority;

                          filterChangeHandler(
                            { vinPriority },
                            props.onFilterChange
                          );
                        }}
                      />
                    ))}
                  </StyledVinPriorityDiv>
                </FilterForm.Field>
              </FilterForm.Column>

              <FilterForm.Column width={2}>
                <FilterForm.VinEquality
                  value={filter.vinEquality}
                  options={vinEquality.map((equality) => ({
                    key: equality.id,
                    value: equality.symbol,
                    text: equality.value,
                  }))}
                  onChange={(value) =>
                    filterChangeHandler(
                      { vinEquality: value },
                      props.onFilterChange
                    )
                  }
                />
              </FilterForm.Column>

              <StyledVinAgeFormColumn width={2}>
                <FilterForm.VinAge
                  value={filter.vinAge}
                  onChange={(value) =>
                    filterChangeHandler(
                      { vinAge: value },
                      debouncedOnFilterChange
                    )
                  }
                />
              </StyledVinAgeFormColumn>
            </FilterForm.Row>

            <FilterForm.Row columns={2}>
              <FilterForm.Column>
                <FilterForm.Customer
                  value={filter.customerId}
                  options={customerOptions}
                  onChange={(value) =>
                    filterChangeHandler(
                      { customerId: value },
                      props.onFilterChange
                    )
                  }
                />
              </FilterForm.Column>
            </FilterForm.Row>
          </>
        )}

        <FilterForm.Row>
          <FilterForm.Column>
            <FilterForm.Search
              value={filter.search}
              onChange={(value) =>
                filterChangeHandler({ search: value }, debouncedOnFilterChange)
              }
            />
          </FilterForm.Column>
        </FilterForm.Row>
      </FilterForm.Grid>
    </FilterForm>
  );
};

const StyledVinPriorityDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

const StyledVinAgeFormColumn = styled(FilterForm.Column)`
  padding-left: 0px !important;
`;

export default OrderPanelFilter;
