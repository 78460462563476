import { useState, useEffect, useMemo, useContext } from "react";
import AppContext from "../../Main/AppContext";
import { debounce } from "lodash-es";

import { useAppSelector } from "../../../store/hooks";
import {
  OrderState,
  ShipmentStates,
  TurvoShipmentStatus,
} from "../../../utils/constants";
import { ShipmentFilter } from "../../../models/ShipmentFilter";
import FilterForm from "../../FilterForm/FilterForm";

const orderStatesOptions = [
  { key: OrderState.Pending, text: "Active", value: OrderState.Pending },
  { key: OrderState.Invisible, text: "In Active", value: OrderState.Invisible },
];

type ShipmentFilterProps = {
  isSearchOnly: boolean;
  onFilterChange: (filter: ShipmentFilter) => void;
};

const ShipmentPanelFilter = (props: ShipmentFilterProps) => {
  const appContext = useContext(AppContext);
  const shipmentFilter = useAppSelector((state) => state.shipments.filter);
  const customersList = useAppSelector((state) => state.customers.list);

  const [filter, setFilter] = useState<ShipmentFilter>(shipmentFilter);

  useEffect(() => {
    setFilter(shipmentFilter);
  }, [shipmentFilter]);

  const debouncedOnFilterChange = useMemo(
    () =>
      debounce((filter: ShipmentFilter) => {
        props.onFilterChange(filter);
      }, 750),
    [props.onFilterChange]
  );

  const filterChangeHandler = (
    partialFilter: Partial<ShipmentFilter>,
    callback: (filter: ShipmentFilter) => void
  ) => {
    const updatedFilter = {
      ...filter,
      ...partialFilter,
      page: 1,
      pageSize: 10,
    };

    setFilter(updatedFilter);
    callback(updatedFilter);
  };

  const customerOptions = useMemo(
    () =>
      customersList
        .map((x) => ({ key: x.id, value: x.id, text: x.name }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [customersList]
  );

  return (
    <FilterForm>
      <FilterForm.Grid>
        {props.isSearchOnly ? null : (
          <>
            <FilterForm.Row columns={2}>
              <FilterForm.Column>
                <FilterForm.Origin
                  value={filter.origin}
                  onChange={(value) =>
                    filterChangeHandler(
                      { origin: value },
                      debouncedOnFilterChange
                    )
                  }
                />
              </FilterForm.Column>

              <FilterForm.Column>
                <FilterForm.Destination
                  value={filter.destination}
                  onChange={(value) =>
                    filterChangeHandler(
                      { destination: value },
                      debouncedOnFilterChange
                    )
                  }
                />
              </FilterForm.Column>
            </FilterForm.Row>

            <FilterForm.Row columns={4}>
              <FilterForm.Column>
                <FilterForm.PickupDate
                  value={filter.pickupDate}
                  dateFormat={appContext.dateFormat}
                  onChange={(value) =>
                    filterChangeHandler(
                      { pickupDate: value },
                      props.onFilterChange
                    )
                  }
                />
              </FilterForm.Column>

              <FilterForm.Column />

              <FilterForm.Column>
                <FilterForm.DeliveryDate
                  value={filter.deliveryDate}
                  dateFormat={appContext.dateFormat}
                  onChange={(value) =>
                    filterChangeHandler(
                      { deliveryDate: value },
                      props.onFilterChange
                    )
                  }
                />
              </FilterForm.Column>
            </FilterForm.Row>

            <FilterForm.Row columns={2}>
              <FilterForm.Column>
                <FilterForm.Customer
                  value={filter.customerId}
                  options={customerOptions}
                  onChange={(value) =>
                    filterChangeHandler(
                      { customerId: value },
                      props.onFilterChange
                    )
                  }
                />
              </FilterForm.Column>
            </FilterForm.Row>

            <FilterForm.Row columns={2}>
              <FilterForm.Column>
                <FilterForm.ShipmentOrderState
                  value={filter.orderStates}
                  options={orderStatesOptions}
                  onChange={(value) =>
                    filterChangeHandler(
                      { orderStates: value },
                      debouncedOnFilterChange
                    )
                  }
                />
              </FilterForm.Column>

              <FilterForm.Column>
                <FilterForm.ShipmentState
                  value={filter.states}
                  options={[...ShipmentStates, ...TurvoShipmentStatus]}
                  onChange={(value) =>
                    filterChangeHandler(
                      { states: value },
                      debouncedOnFilterChange
                    )
                  }
                />
              </FilterForm.Column>
            </FilterForm.Row>
          </>
        )}

        <FilterForm.Row>
          <FilterForm.Column>
            <FilterForm.Search
              value={filter.search}
              onChange={(value) =>
                filterChangeHandler({ search: value }, debouncedOnFilterChange)
              }
            />
          </FilterForm.Column>
        </FilterForm.Row>
      </FilterForm.Grid>
    </FilterForm>
  );
};

export default ShipmentPanelFilter;
