import { useEffect, useState } from "react";
import { Button, SemanticSIZES } from "semantic-ui-react";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { clearReshuffle } from "../../../../store/reshuffleSlice";
import ReshuffleModal from "./ReshuffleModal";

type ShipmentPanelReshuffleModalProps = {
  size: SemanticSIZES;
};

const ShipmentPanelReshuffleModal = (
  props: ShipmentPanelReshuffleModalProps
) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isDefaultFilter = useAppSelector(
    (state) => state.orders.isDefaultFilter
  );
  const orderFilter = useAppSelector((state) => state.orders.filter);
  const shipmentFilter = useAppSelector((state) => state.shipments.filter);
  const invalidReshuffle = useAppSelector((state) => state.reshuffle.invalid);

  const ineligibleForReshuffleFilterStates = [
    2102, 2103, 2104, 2105, 2106, 2107, 2108, 2109, 2112, 2113, 2115, 2116,
    2119,
  ];
  const shipmentFilterContainsIneligibileState = shipmentFilter.states.some(
    (state) => ineligibleForReshuffleFilterStates.includes(state)
  );

  const handleClose = () => {
    setIsOpen(false);
    dispatch(clearReshuffle());
  };

  useEffect(() => {
    if (invalidReshuffle) {
      handleClose();
    }
  }, [invalidReshuffle]);

  return (
    <>
      <Button
        size={props.size}
        onClick={() => setIsOpen(true)}
        content="Reshuffle"
        icon="shuffle"
        disabled={
          isDefaultFilter ||
          !orderFilter.customerId ||  // Disable if orderFilter.customerId is empty
          !shipmentFilter.customerId || // Disable if shipmentFilter.customerId is empty
          orderFilter.customerId !== shipmentFilter.customerId ||
          shipmentFilterContainsIneligibileState
        }
      />
      <ReshuffleModal isOpen={isOpen} onClose={handleClose} />
    </>
  );
};

export default ShipmentPanelReshuffleModal;
