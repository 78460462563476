import {
  Checkbox,
  CheckboxProps,
  Dropdown,
  DropdownProps,
  Form,
  FormFieldProps,
  FormProps,
  Grid,
  GridColumnProps,
  GridProps,
  GridRowProps,
  Input,
} from "semantic-ui-react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { VinPriority } from "../../models/VinPriority";

const FilterForm = (props: FormProps) => <StyledForm {...props} />;

FilterForm.Grid = (props: GridProps) => <Grid {...props} />;
FilterForm.Row = (props: GridRowProps) => <StyledRow {...props} />;
FilterForm.Column = (props: GridColumnProps) => <Grid.Column {...props} />;
FilterForm.Field = (props: FormFieldProps) => <Form.Field {...props} />;

FilterForm.Origin = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="origin">Origin</label>
      <Input
        id="origin"
        value={props.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.onChange(event.target.value)
        }
      />
    </FilterForm.Field>
  );
};

FilterForm.Destination = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="destination">Destination</label>
      <Input
        id="destination"
        value={props.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.onChange(event.target.value)
        }
      />
    </FilterForm.Field>
  );
};

FilterForm.PickupDate = (props: {
  value: string;
  onChange: (value: string) => void;
  dateFormat: string;
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="pickup-date">Pickup Date</label>
      <DatePicker
        id="pickup-date"
        placeholderText="Select a date"
        selected={props.value ? new Date(props.value) : null}
        onChange={(date: Date | null) =>
          props.onChange(date?.toISOString() ?? "")
        }
        dateFormat={props.dateFormat}
        isClearable
      />
    </FilterForm.Field>
  );
};

FilterForm.DeliveryDate = (props: {
  value: string;
  onChange: (value: string) => void;
  dateFormat: string;
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="delivery-date">Delivery Date</label>
      <DatePicker
        id="delivery-date"
        placeholderText="Select a date"
        selected={props.value ? new Date(props.value) : null}
        onChange={(date: Date | null) =>
          props.onChange(date?.toISOString() ?? "")
        }
        dateFormat={props.dateFormat}
        isClearable
      />
    </FilterForm.Field>
  );
};

FilterForm.Customer = (props: {
  value: string | number;
  onChange: (value: string | number) => void;
  options: { key: number; value: number; text: string }[];
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="customer-id">Customer</label>
      <Dropdown
        id="customer-id"
        aria-label="customer-id"
        placeholder="Select a customer"
        options={props.options}
        value={props.value}
        onChange={(
          _event: React.SyntheticEvent<HTMLElement, Event>,
          data: DropdownProps
        ) => props.onChange(typeof data.value === "number" ? data.value : "")}
        clearable
        selection
        fluid
        search
      />
    </FilterForm.Field>
  );
};

FilterForm.Search = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <StyledSearchInput
      size="small"
      icon="search"
      placeholder="Search..."
      value={props.value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        props.onChange(event.target.value)
      }
      focus
      fluid
    />
  );
};

FilterForm.ShipmentOrderState = (props: {
  value: number[];
  onChange: (value: number[]) => void;
  options: { key: number; value: number; text: string }[];
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="shipment-order-state">
        Shipment with Order Status of
      </label>
      <Dropdown
        id="shipment-order-state"
        aria-label="shipment-order-state"
        options={props.options}
        value={props.value}
        onChange={(
          _event: React.SyntheticEvent<HTMLElement, Event>,
          data: DropdownProps
        ) => props.onChange(data.value as number[])}
        selection
        multiple
        fluid
      />
    </FilterForm.Field>
  );
};

FilterForm.ShipmentState = (props: {
  value: number[];
  onChange: (value: number[]) => void;
  options: { key: number; value: number; text: string }[];
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="shipment-state">Shipment Status</label>
      <Dropdown
        id="shipment-state"
        aria-label="shipment-state"
        options={props.options}
        value={props.value}
        onChange={(
          _event: React.SyntheticEvent<HTMLElement, Event>,
          data: DropdownProps
        ) => props.onChange(data.value as number[])}
        selection
        multiple
        fluid
      />
    </FilterForm.Field>
  );
};

FilterForm.VinPriority = (props: {
  vinPriority: VinPriority;
  onChange: (value: number) => void;
}) => {
  return (
    <StyledVinPriorityField>
      <Checkbox
        id={`vin-priority-${props.vinPriority.id}`}
        value={props.vinPriority.id}
        onChange={(
          _event: React.FormEvent<HTMLInputElement>,
          data: CheckboxProps
        ) => props.onChange(data.value as number)}
      />
      <label htmlFor={`vin-priority-${props.vinPriority.id}`}>
        {props.vinPriority.value}
      </label>
    </StyledVinPriorityField>
  );
};

FilterForm.VinEquality = (props: {
  value: string;
  onChange: (value: string) => void;
  options: { key: number; value: string; text: string }[];
}) => {
  return (
    <FilterForm.Field>
      <label htmlFor="vin-equality">VIN is</label>
      <Dropdown
        id="vin-equality"
        options={props.options}
        value={props.value}
        onChange={(
          _e: React.SyntheticEvent<HTMLElement, Event>,
          data: DropdownProps
        ) => props.onChange(data.value as string)}
        clearable
        selection
        fluid
      />
    </FilterForm.Field>
  );
};

FilterForm.VinAge = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <StyledVinAgeField>
      <input
        id="vin-age"
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
      <StyledVinAgeLabel htmlFor="vin-age">days old</StyledVinAgeLabel>
    </StyledVinAgeField>
  );
};

const StyledForm = styled(Form)`
  padding-bottom: 0.5rem;
`;

const StyledRow = styled(Grid.Row)`
  padding: 0.2rem 0 !important;
`;

const StyledVinPriorityField = styled(FilterForm.Field)`
  display: flex;
  gap: 0.25rem;
  margin: 0.5rem 0 !important;
`;

const StyledVinAgeField = styled(FilterForm.Field)`
  display: flex;
  align-items: start;
  margin-top: 24px !important;
  gap: 0.5rem;
`;

const StyledVinAgeLabel = styled.label`
  margin-bottom: 0px !important;
`;

const StyledSearchInput = styled(Input)`
  margin: 0.25rem 0 !important;
  > input {
    border-color: #85b7d9 !important;
  }
`;

export default FilterForm;
